@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      font-family: Helvetica Neue, system-ui, sans-serif;
      color: rgb(156 163 175);

    }
  }

  .field {
    width: 30rem;
    font-size: 5rem;
    padding-top: min(30%, 12rem);
    justify-self: center;
  }

 /* rgb(156 163 175) */